<template>
  <section>
    <v-toolbar flat>
      <v-btn
        icon
        to="/"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-divider
        class="mx-3"
        inset
        vertical
      />
      Settings
    </v-toolbar>
    <v-divider />

    <div v-if="!connected">
      Please connect your wallet..
    </div>
    <div v-else-if="!unlocked">
      Please unlock your wallet..
    </div>
    <div v-else class="pa-3">
      <div class="mb-3">
        Language
      </div>

      <v-form class="mb-3">
        <v-select
          v-model="language"
          :items="items"
          prepend-inner-icon="mdi-earth"
          label="Language"
          flat
          solo
          style="max-width: 400px"
        />

        Avatar: <v-avatar @click.stop="dialog = true"><v-img /></v-avatar>

        <v-flex>
          <v-file-input
            ref="myfile"
            v-model="files"
            show-size
            counter
            chips
            multiple
            label="Arquivo Geral"
          />
        </v-flex>
        <v-flex>
          <v-btn color="primary" text @click="submitFiles">test</v-btn>
        </v-flex>

        <v-dialog
          v-model="dialog"
          max-width="290"
        >
          Pick you avatar!<br>
          <v-avatar v-for="i in avatar_list" :key="i" @click="pickAvatar(i)"><v-img /></v-avatar>
        </v-dialog>

        <v-divider />

        <v-checkbox label="Use Proxy" />

        <v-text-field
          ref="username"
          v-model="username"
          class="py-2"
          hide-details
          label="Username"
          name="username"
          outlined
          single-line
        />
        <v-text-field
          ref="password"
          v-model="pw"
          class="py-2"
          hide-details
          label="Password"
          name="password"
          outlined
          single-line
        />

        <v-btn @click="doSave">SAVE</v-btn>

        <v-divider class="mb-3" />

        <v-sheet max-width="400">
          <v-list>
            <v-list-item @click="() => {}">
              <v-list-item-title>Edit Cached Storage Location</v-list-item-title>
            </v-list-item>
            <v-list-item @click="() => {}">
              <v-list-item-title>Troubleshoot</v-list-item-title>
            </v-list-item>
            <v-list-item
              href="https://community.vuetifyjs.com"
              target="_blank"
              rel="noopener"
            >
              <v-list-item-title>Support</v-list-item-title>
            </v-list-item>
            <v-list-item @click="() => {}">
              <v-list-item-title>Show Logs</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-form>

      <v-divider class="mb-5" />

      <div class="text-center subheading">
        Good Game | 2021
      </div>
    </div>
  </section>
</template>

<script>
  import {
    mapState,
    mapActions,
  } from 'vuex'
  export default {
    data: () => ({
      dialog: false,
      avatar: '',
      avatar_list: [],
      username: '',
      pw: '',
      items: [{
        value: 'en',
        text: 'English (United States)',
      }],
      language: 'en',
      files: null,
    }),
    computed: {
      ...mapState('wallet', ['connected', 'profileAvatar', 'address', 'unlocked', 'mainHost']),
    },
    watch: {
      unlocked () {
        console.log('unlock?')
        this.avatar = this.profileAvatar
      },
    },
    async mounted () {
      console.log('load avatar list..')
      this.avatar = this.profileAvatar
    },
    methods: {
      ...mapActions('wallet', ['signMsg']),
      pickAvatar (avatarId) {
        this.avatar = avatarId
        this.dialog = false
      },
      submitFiles () {
        if (this.files) {
          const formData = new FormData()
          // files
          for (const file of this.files) {
            formData.append('files', file, file.name)
          }

          // additional data
          formData.append('addr', this.address)

          this.$http
            .post(this.mainHost + '/upload_avatar', formData)
            .then(response => {
              console.log('Success!')
              console.log({ response })
            })
            .catch(error => {
              console.log({ error })
            })
        } else {
          console.log('there are no files.')
        }
      },
      uploadAvatar () {
        console.log('do up')
      },
      doSave () {
        console.log('t', this)
        var username = this.username
        var pw = this.pw
        var avatar = this.avatar

        this.signMsg({ msg: 'save_cfg', username, pw, avatar })
        console.log('xasave')
      },
    },
  }
</script>
